
import async from "../components/Async";
import {
  faBook,
  faChartPie,
  faCheckSquare,
  faDesktop,
  faFile,
  faFlask,
  faHeart,
  faHome,
  faMapMarkerAlt,
  faTable,
  faSignInAlt,
  faListAlt,
  faTicketAlt,
  faList,
  faVectorSquare,
  faBold
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

// Landing
import Landing from "../pages/auth/SignIn";

//DK
import Pakkeliste from "../pages/dk/modules/pakkeliste";

import Riggeliste from "../pages/dk/modules/riggeliste";
import OrderTable from "../pages/dk/modules/webhookLogs";

//NO


import DashboardDefaultPage from "../pages/no/modules/ticketSystem/Dashboard";
import Konkurranse from "../pages/no/modules/ticketSystem/Konkurranse";
import Config from "../pages/ticketSystem/config";

import MediaReport from "../pages/no/modules/ticketSystem/Mediareport";

import SalesOppTable from "../pages/24so-soap/salesopportunity";
import Rigginglist from "../pages/24so-soap/Rigginglist";
import Pakkeliste_NO from "../pages/24so-soap/pakkeliste";
import ProjectSelector from "../pages/24so-soap/ProjectSelector";
import AssemblerSettings from "../pages/24so-soap/Assember";

import ProductsDeletion from "../pages/24so-soap/ProductsDeletion";
import Orders24SO from "../pages/24so-soap/modules/orders";
import ImportExhibitors from "../pages/24so-soap/modules/importExhibitors";
import SalesOpp from "../pages/24so-soap/boligmesse/salesopportunity";
import Customers from "../pages/24so-soap/boligmesse/Customers_new";
// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
import SidebarLeft from "../pages/layouts/SidebarLeft";
import SidebarRight from "../pages/layouts/SidebarRight";
import ThemeModern from "../pages/layouts/ThemeModern";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeDark from "../pages/layouts/ThemeDark";
import ThemeLight from "../pages/layouts/ThemeLight";

// Misc
import Blank from "../pages/misc/Blank";


// Admin/Users
import Users from "../pages/admin/users/users";

import Tables from "../../src/pages/tables/Advanced";


// Pages
const Tasks = async(() => import("../pages/pages/Tasks"));


// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

const Default2 = async(() => import("../pages/no/modules/ticketSystem/Default"));


const Default24SO = async(() => import("../pages/24so-soap/dashboard/index"));


// Routes
const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: Landing,
  children: null
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Default,
  
};

const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: faDesktop,
  children: [
    {
      path: "/layouts/sidebar-left",
      name: "Left Sidebar",
      component: SidebarLeft
    },
    {
      path: "/layouts/sidebar-right",
      name: "Right Sidebar",
      component: SidebarRight
    },
    {
      path: "/layouts/theme-modern",
      name: "Modern Theme",
      component: ThemeModern
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-dark",
      name: "Dark Theme",
      component: ThemeDark
    },
    {
      path: "/layouts/theme-light",
      name: "Light Theme",
      component: ThemeLight
    }
  ]
};

const pageRoutes = {
  path: "/expo-solutions",
  name: "Expo Solutions",
  icon: faVectorSquare,
  Permissions: ['Administrator','Expo Solutions', 'Chief Assembler', 'Assembler', 'Fairs'],
  component: Default24SO,
  children: [
    {
      path: "/expo-solutions/dashboard",
      name: "Dashboard",
      Permissions: ['Administrator', 'Expo Solutions', 'Chief Assembler', 'Assembler', 'Fairs'],
      component: Default24SO,
      
    },
    {
      path: "/expo-solutions/tasks",
      name: "Tasks",
      Permissions: ['Administrator', 'Expo Solutions', 'Chief Assembler', 'Fairs'],
      component: Tasks,
      
    },
    {
      path: "/expo-solutions/checklist",
      name: "Checklist",
      Permissions: ['Administrator', 'Expo Solutions', 'Chief Assembler', 'Assembler', 'Fairs'],
      component: Rigginglist,
      
    },

    {
      path: "/expo-solutions/pakkeliste",
      name: "Packing list",
      Permissions: ['Administrator', 'Expo Solutions', 'Chief Assembler', 'Assembler', 'Fairs'],
      component: Pakkeliste_NO,
      
    },
    {
      path: "/expo-solutions/assemblers",
      name: "Assemblers",
      Permissions: ['Administrator', 'Expo Solutions','Chief Assembler', 'Fairs'],
      component: AssemblerSettings,
      icon: faHome,
      
    },
    {
      path: "/expo-solutions/Kiosk",
      name: "Kiosk",
      Permissions: ['Administrator', 'Expo Solutions', 'Chief Assembler', 'Fairs'],
      component: ProductsDeletion,
      
    },
    {
      path: "/expo-solutions/config",
      name: "Config",
      Permissions: ['Administrator', 'Expo Solutions', 'Fairs', 'Chief Assembler', 'Assembler'],
      component: ProjectSelector,
      
    },

    {
      path: "/expo-solutions/import-exhibitors",
      name: "Import Exhibitors",
      Permissions: ['Administrator', 'Expo Solutions', 'Fairs'],
      component: ImportExhibitors,
      
    },

    
    
  ],


  
  

};


const projectDK = {
  path: "/dk",
  name: "Lists",
  icon: faListAlt,
  Permissions: ['Administrator', 	'Admin-DK'],
  children: [
    {
      path: "/dk/packinglist",
      name: "Packing List",
      Permissions: ['Administrator', 'Admin-DK'],
      component: Pakkeliste,
    },

    {
      path: "/dk/rigginglist",
      name: "Rigging List",
      Permissions: ['Administrator', 'Admin-DK'],
      component: Riggeliste,
    },
    {
      path: "/dk/economic-logs",
      name: "Economic logs",
      Permissions: ['Administrator', 'Admin-DK'],
      component: OrderTable,
    },

    
  ]

};

const projectNO = {
  path: "/no",
  name: "Tickets",
  icon: faTicketAlt,
  Permissions: ['Administrator', 'Fairs', 'Media', 'Messe'],
  children: [
    {
      path: "/no/ticket/dashboard",
      name: "Dashboard",
      Permissions: ['Administrator','Fairs','Media', 'Messe'],
      component: Default2,
    },
    {
      path: "/no/ticket/exhibitorReport",
      name: "Exhibitor",
      Permissions: ['Administrator', 'Fairs'],
      component: DashboardDefaultPage,
    },
    {
      path: "/no/ticket/wpTicket",
      name: "All sources",
      Permissions: ['Administrator', 'Media'],
      component: MediaReport,
    },
    {
      path: "/no/ticket/Registreringskonkurranse",
      name: "Registreringskonkurranse",
      Permissions: ['Administrator', 'Media', 'Fairs'],
      component: Konkurranse,
    },
    {
      path: "/no/ticket/config",
      name: "Config",
      Permissions: ['Administrator'],
      component: Config,
    },
  ]

};



const reportsNO = {
  path: "/reports",
  name: "Reports",
  icon: faList,
  Permissions: ['Administrator', 'Fairs', 'Expo Solutions'],
  children: [
    {
      path: "/reports/orders/",
      name: "Orders",
      Permissions: ['Administrator', 'Fairs', 'Expo Solutions'],
      component: Orders24SO,
    },
    {
      path: "/reports/customers",
      name: "Customers",
      Permissions: ['Administrator', 'Fairs', 'Expo Solutions'],
      component: Customers,
    },
  ]

};

const boligmesseNO = {
  path: "/boligmesse",
  name: "Boligmesse",
  icon: faBold,
  Permissions: ['Administrator', 'Fairs'],
  children: [
    {
      path: "/boligmesse/salesopp",
      name: "Exhibitors List",
      Permissions: ['Administrator', 'Fairs'],
      component: SalesOpp,
    },

  ]

};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
  ]
}; //Get new changes

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  Permissions: ['Administrator'],
  children: [
    {
      path: "/admin/users",
      name: "Blank Page",
      component: Users
    },
    {
      path: "/admin/usersv2",
      name: "Blank Page",
      component: Tables
    },
    
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  layoutRoutes,
  privateRoutes,
  pageRoutes,
  projectDK,
  projectNO,
  reportsNO,
  boligmesseNO
  
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  pageRoutes,
  projectDK,
  projectNO,
  reportsNO,
  boligmesseNO 
];
