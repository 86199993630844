import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import axios from 'axios';
import { toastr } from "react-redux-toastr";
const EditStandModal = ({ isOpen, toggle, index, invoice, projectId }) => {
  const [value, setValue] = useState('');
  const handleSave = async () => {
    try {
      
      const url = `https://api2.fairs.as/24so/soap/customStands/${projectId}`;

      // Post the index and value to the backend
      const response = await axios.post(url, {
        index: index,
        value: value,
        invoiceDetails: invoice, // You can also send additional invoice data if needed
      });

      console.log('Response from server:', response.data);
      toastr.success("[OK]", `${response.data.message}`);
      // Close the modal after successful post
      toggle();
    } catch (error) {
        toastr.error("[ERROR]", `Something went wrong: ${error}`);
      console.error('Error posting data:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Stand</ModalHeader>
      <ModalBody>
        <p>Editing stand for invoice: {invoice.CustomerName}</p>
        <p>Index: {index}</p>
        <Input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Enter new stand value"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>Save</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditStandModal;
