import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import WebTicketsTable from "./Components/webtickets";
import ExhibitorsTable from "./Components/exhibitorticket";
import ProjectSelector from "./Components/projectSelector";

const MediaReport = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [data, setData] = useState({
    webTickets: [],
    exhibitors: [],
    expoBox: []
  });

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    fetchData(project.value); 
  };

  const fetchData = async (selectedProjectId) => {
    try {
      const response = await fetch(
        `https://boligmesse.no/nb/wp-json/RJSDashboard/v1/data?ticket=${selectedProjectId}`
      );
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
     if(selectedProject) fetchData(selectedProject.value);
  }, [selectedProject]);

  const { webTickets, exhibitors, totalUsedWEB, totalOrderedWEB } = data;

  // Filter and aggregate tickets where referer starts with "k_"
  const accumulatedTicket = webTickets
    .filter(ticket => ticket.referer.startsWith("k_"))
    .reduce(
      (acc, ticket) => {
        acc.ordered += parseInt(ticket.ordered || 0, 10);
        acc.used += parseInt(ticket.used || 0, 10);
        return acc;
      },
      { referer: "Registreringskonkurranse", ordered: 0, used: 0 }
    );

  // Combine accumulated ticket with non "k_" tickets
  const filteredWebTickets = [
    accumulatedTicket,
    ...webTickets.filter(ticket => !ticket.referer.startsWith("k_"))
  ];

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Ticket Report</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Ticket Report System</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 pt-2">
                Select Project
              </CardTitle>
            </CardHeader>
            <CardBody>
              <ProjectSelector
                apiUrl={"https://boligmesse.no"}
                onProjectSelect={handleProjectSelect}
              />
              <div className="my-3"></div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          {webTickets.length > 0 && (
            <div>
              <WebTicketsTable 
                webTickets={filteredWebTickets} 
                totalUsedWEB={totalUsedWEB} 
                totalOrderedWEB={totalOrderedWEB} 
              />
            </div>
          )}
        </Col>
        <div style={{ margin: "20px 0" }} />
        <Col>
          {exhibitors.length > 0 && (
            <div>
              <ExhibitorsTable 
                exhibitors={exhibitors} 
                totalUsedWEB={data.totalUsedEx} 
                totalOrderedWEB={data.totalOrderedEx} 
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MediaReport;
