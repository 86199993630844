// projectSelectorComponent.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import axios from 'axios';

const ProjectSelect = ({ onProjectSelect }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      setIsLoading(true);
      try {
        const apiUrl = "https://api2.fairs.as/24so/soap/projects";
        const response = await axios.get(apiUrl);

        const projects = response.data.Project.map((item) => ({
          value: item.Id,
          label: item.Name,
          type: item.TypeName,
        }));

        setProjectOptions(projects);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadOptions();
  }, []);

  // Handle project selection and pass it to the parent component
  const handleProjectChange = (selectedProject) => {
    onProjectSelect(selectedProject);
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0 pt-2">
              Select Project
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Select
              options={projectOptions}
              onChange={handleProjectChange}
              isLoading={isLoading}
              placeholder="Search and select a project..."
              isClearable
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ProjectSelect;
