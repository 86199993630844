import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

const ExhibitorsTable = ({ exhibitors, totalUsedWEB, totalOrderedWEB }) => {
  // Sort the exhibitors array alphabetically by 'name' before using it
  const sortedExhibitors = exhibitors.sort((a, b) => a.name.localeCompare(b.name));

  // Ensure the exhibitor data has numeric types
  const formattedExhibitors = sortedExhibitors.map(exhibitor => ({
    ...exhibitor,
    total: Number(exhibitor.total), // Ensure total is a number
    used: Number(exhibitor.used) // Ensure used is a number
  }));

  const columns = [
    {
      dataField: 'name',
      text: 'Navn',
      sort: true, // Enables sorting
      headerStyle: { width: '35%' }
    },
    {
      dataField: 'total',
      text: 'Bestilte',
      sort: true, // Enables sorting
      headerStyle: { width: '25%' }
    },
    {
      dataField: 'used',
      text: 'Brukte',
      sort: true, // Enables sorting
      headerStyle: { width: '20%' }
    },
  ];

  const columnsSeperate = [
    {
      dataField: 'referer',
      text: '',
      sort: true, // Enables sorting
      headerStyle: { width: '35%' }
    },
    {
      dataField: 'ordered',
      text: '',
      sort: true, // Enables sorting
      headerStyle: { width: '25%' }
    },
    {
      dataField: 'used',
      text: '',
      sort: true, // Enables sorting
      headerStyle: { width: '20%' }
    },
  ];

  const TotalRow = () => (
    <tr>
      <td><b style={{ fontSize: 'large' }}>{'Totals:'}</b></td>
      <td>{totalOrderedWEB}</td>
      <td>{totalUsedWEB}</td>
    </tr>
  );

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle tag="h3" className="mb-0">
            Utstiller
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ToolkitProvider
            keyField="name" // The unique key for each row
            data={[...formattedExhibitors]} // Adding totals row to data
            columns={columns}
            search
          >
            {
              props => (
                <>
                  <Search.SearchBar {...props.searchProps} placeholder="Search..." />
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    bordered={false}
                  />
                </>
              )
            }
          </ToolkitProvider>
          <ToolkitProvider
            keyField="referer" // The unique key for each row
            data={[{referer: 'Totals', ordered : totalOrderedWEB, used : totalUsedWEB}]} // Adding totals row to data
            columns={columnsSeperate}
          >
            {
              props => (
                <>
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    bordered={false}
                    rowStyle={{ backgroundColor: '#d4f8e8' }} 
                  />
                </>
              )
            }
          </ToolkitProvider>

        </CardBody>
        
      </Card>
      
    </div>
  );
};

export default ExhibitorsTable;
