import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const ProjectSelector = ({ onProjectSelect }) => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    loadOptions("");
  }, []);

  useEffect(() => {
    fetchCurrentProject();
  }, [projectOptions]);

  const loadOptions = async (inputValue) => {
    setIsLoading(true);
    try {
      const apiUrl = "https://api2.fairs.as/24so/soap/projects";
      const response = await axios.get(apiUrl);

      const projectOptions = response.data.Project.map((item) => ({
        value: item.Id,
        label: item.Name,
        type: item.TypeName,
      }));

      setProjectOptions(projectOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
      toastr.error("Error", "Failed to fetch project data.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrentProject = async () => {
    try {
      const response = await axios.get("https://tickets.fairs.as/ticket-capture/current-project");
      const currentProjectId = response?.data?.projectId;

      if (currentProjectId) {
        const project = projectOptions.find(option => option.value === parseInt(currentProjectId));
        console.log(project); 
        setSelectedProject(project || null);
      }
    } catch (error) {
      console.error("Error fetching current project:", error);
      toastr.error("Error", "Failed to fetch current project data.");
    }
  };
  

  const handleProjectChange = async (selectedOption) => {
    setSelectedProject(selectedOption);
    if (selectedOption) {
      const projectId = selectedOption.value;
      try {
        const response = await axios.post("https://tickets.fairs.as/ticket-capture/update-project", {
          projectId: projectId,
        });

        if (response.status === 201) {
          toastr.success("Success", "Project updated successfully!");
        } else {
          toastr.error("Error", "Failed to update the project.");
        }
      } catch (error) {
        console.error("Error updating project:", error);
        toastr.error("Error", "Failed to update the project.");
      }
    }
  };

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>Config</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Tickets</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Config</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Sets current project for the new ticket system
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Select
                      options={projectOptions}
                      isSearchable
                      placeholder="Select or search for projects..."
                      isLoading={isLoading}
                      onChange={handleProjectChange}
                      value={selectedProject} 
                    />
                  </div>
                </div>
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectSelector;
